import React, { useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import ClaseForm from "../../components/clases/ClaseForm";
import Schedule from "../../components/clases/Schedule";

const AdminClases = () => {
  const { setPropiedadClase } = useContext(ClassInstructorContext);

  const { modalComponent } = useContext(ModalContext);

  const addClase = () => {
    modalComponent(
      "Agregar Clase",
      <ClaseForm class_instructor_id="nueva" modifier={setPropiedadClase} />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Clases" onClick={addClase} />
      <Schedule layout="month" />
    </div>
  );
};

export default AdminClases;
