import React, { useState } from "react";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";

const PurchaseRow = ({
  user,
  paquete,
  editExpiration,
  confirmCancel,
  confirmRevoke,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [days, setDays] = useState(paquete.expiration_days);

  const handleEdit = () => {
    if (editMode) {
      setEditMode(false);
      return editExpiration(paquete.purchase_id, days, paquete.customer_id);
    }
    setEditMode(true);
  };

  const canEditExpiration = () => {
    return (
      paquete.subscription_id === null &&
      paquete.subscription_period === null &&
      canCancel()
    );
  };

  const canCancel = () => {
    return (
      confirmCancel &&
      paquete.status !== "cancelled" &&
      paquete.status !== "revoked" &&
      paquete.status !== "pending"
    );
  };

  const canRevoke = () => {
    return confirmRevoke && paquete.status === "cancelled";
  };

  const renderActions = () => {
    if (confirmCancel || confirmRevoke) {
      return (
        <td>
          {canCancel() && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => confirmCancel(paquete)}
            >
              <i className="fa fa-times"></i> Cancelar
            </button>
          )}
          {canRevoke() && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => confirmRevoke(paquete)}
            >
              <i className="fa fa-ban"></i> Revocar
            </button>
          )}
        </td>
      );
    }
  };

  const renderExpiration = () => {
    if (editMode) {
      return (
        <input
          type="number"
          className="form-control w-50 d-inline"
          value={days}
          onChange={(e) => setDays(e.target.value)}
        />
      );
    }

    if (paquete.purchase?.subscription_period && paquete.purchase?.subscription_interval) {
      let dia;
      if (paquete.free_trial_length && paquete.free_trial_length !== null) {
        dia = moment(paquete.createdAt)
          .utc()
          .add(paquete.free_trial_length, "days")
          .format("DD");
      } else {
        dia = moment(paquete.createdAt).utc().format("DD");
      }
      return `El ${dia} cada ${paquete.purchase.subscription_interval} ${
        paquete.subscription_period === "month"
          ? "mes"
          : paquete.subscription_period === "day"
          ? "día"
          : "año"
      }`;
    }
    return moment(paquete.createdAt)
      .add(paquete.expiration_days, "days")
      .format("DD MMM YYYY");
  };

  const renderCustomer = () => {
    if (paquete.customer && paquete.customer !== null) {
      return (
        <Link to={`/myadmin/customer/${paquete.customer.customer_id}`}>
          <i className="fa fa-eye"></i> {paquete.customer.name}{" "}
          {paquete.customer.last_name}
        </Link>
      );
    }
  };

  const isFirstPurchase = () => {
    if (paquete.customer && paquete.customer !== null) {
      if (Array.isArray(paquete.customer.invoices))
        return paquete.customer.invoices.length === 1;
    }
  };

  const renderDiscountCode = () => {
    if (paquete.discount && paquete.discount !== null) {
      return paquete.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (paquete.class_package && paquete.class_package !== null) {
      return paquete.class_package.title;
    }
  };

  const renderPaymentMethod = () => {
    if (paquete.payment_method && paquete.payment_method !== null) {
      return paquete.payment_method.name;
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {paquete.purchase_id}{" "}
        {paquete.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      <td>{renderCustomer()}</td>
      <td>{renderClassPackage()}</td>
      <td>{moment(paquete.createdAt).utc().format("DD MMM YYYY HH:mm")}</td>
      <td>
        {paquete.is_gift && <i className="fas fa-gift me-1"></i>}
        {user.role === "admin" && (
          <>
            {"$"}
            {formatMonto(paquete.total_payment)} MXN
          </>
        )}
      </td>
      <td>
        {renderExpiration()}
        {canEditExpiration() && (
          <button
            className="btn btn-link btn-sm text-dark mx-2"
            onClick={handleEdit}
          >
            <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
          </button>
        )}
      </td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={paquete.status} date={paquete.updatedAt} />
      </td>
      <td>{renderDiscountCode()}</td>
      {renderActions()}
    </tr>
  );
};

export default PurchaseRow;
