import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { Link } from "@reach/router";
import CustomersTable from "../../components/customers/CustomersTable";
import Pagination from "../../components/global/Pagination";

const AdminClientes = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const { customers, getAllCustomers } = useContext(CustomerContext);

  useEffect(() => {
    getAllCustomers({ page });
  }, []);

  useEffect(() => {
    if (query.length > 0) {
      const timeout = setTimeout(() => {
        getAllCustomers({ query, page });
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      getAllCustomers({ page });
    }
  }, [query, page]);

  return (
    <div className="container-fluid">
      <div className="row mx-0 align-items-center mb-3 pb-2 border-bottom">
        <div className="col-12 col-md-4 ps-0">
          <h1>Clientes</h1>
        </div>
        <div className="col-12 col-md-8 px-0">
          <div className="row">
            <div className="col-6 col-md-8">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4 text-right">
              <Link
                to="/myadmin/customer/nuevo/edit"
                className="btn btn-accent w-100"
              >
                + Agregar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card no-scale p-3">
        <CustomersTable customers={customers} />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminClientes;
