import React from "react";
import PurchaseRow from "./PurchaseRow";

const PurchasesTable = ({
  user,
  purchases,
  editExpiration,
  confirmCancel,
  confirmRevoke,
}) => {
  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      return purchases.map((purchase) => (
        <PurchaseRow
          user={user}
          paquete={purchase}
          key={purchase.purchase_id}
          editExpiration={editExpiration}
          confirmCancel={confirmCancel}
          confirmRevoke={confirmRevoke}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Cliente</td>
            <td>Paquete</td>
            <td>Fecha</td>
            <td>Precio</td>
            <td>Corte</td>
            <td>Pago</td>
            <td>Estado</td>
            <td>Descuento</td>
            {(confirmCancel || confirmRevoke) && <td>Acciones</td>}
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
