import React from "react";
import ReactSwitch from "react-switch";

const ToggleOption = ({ value, label, modifier }) => {
  return (
    <div className="row mb-3">
      <div className="col-6">
        <label>{label}</label>
      </div>
      <div className="col-6">
        <ReactSwitch
          checked={value}
          onChange={(checked) => modifier(checked)}
        />
      </div>
    </div>
  );
};

export default ToggleOption;