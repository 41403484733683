import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { ClassPackagesContext } from "../../context/ClassPackagesContext";
import { AuthContext } from "../../context/AuthContext";
import ReservacionRow from "../../components/reservaciones/ReservacionRow";
import CustomerInfo from "../../components/customers/CustomerInfo";
import { PurchasesContext } from "../../context/PurchasesContext";
import RevokeForm from "../../components/purchases/RevokeForm";
import { hideModal } from "../../utils";
import RemoveClassesForm from "../../components/purchases/RemoveClassesForm";
import AddClassesForm from "../../components/purchases/AddClassesForm";
import CancelForm from "../../components/purchases/CancelForm";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import CustomerData from "../../components/customers/CustomerData";
import CustomerActions from "../../components/customers/CustomerActions";
import { navigate } from "@reach/router";

const AdminSingleUsuario = ({ customer_id }) => {
  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
    addCustomerClasses,
    removeCustomerClasses,
  } = useContext(CustomerContext);
  const { paquetes, getPaquetes } = useContext(ClassPackagesContext);

  const { user } = useContext(AuthContext);

  const { modalComponent } = useContext(ModalContext);

  const { cancelPurchase, revokePurchase } = useContext(PurchasesContext);

  useEffect(() => {
    getViewData();
    return clearCustomer;
  }, []);

  const getViewData = () => {
    getCustomer(customer_id);
    getPaquetes();
  }

  const toggleModal = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        extenderAcceso={extenderAcceso}
        paquetes={paquetes}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const confirmRevoke = (purchase) => {
    modalComponent(
      "Precaución",
      <RevokeForm
        purchase={purchase}
        customer_id={customer_id}
        revokePurchase={revokePurchase}
      />
    );
  };

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        customer_id={customer_id}
        cancelPurchase={cancelPurchase}
      />
    );
  };

  const confirmRemoveClasses = () => {
    modalComponent(
      "Restar Clases",
      <RemoveClassesForm
        customer_id={customer_id}
        removeClasses={removeCustomerClasses}
      />
    );
  };

  const confirmAddClasses = () => {
    modalComponent(
      "Agregar Clases",
      <AddClassesForm
        customer_id={customer_id}
        addClasses={addCustomerClasses}
      />
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      return (
        <CustomerInfo
          customer={customer}
          handleAddClasses={confirmAddClasses}
          handleRevokeClasses={confirmRemoveClasses}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderPanel = () => {
    if (customer && customer !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions customer={customer} />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderReservaciones = () => {
    if (customer && customer !== null) {
      if (Array.isArray(customer.class_reservations)) {
        return customer.class_reservations.map((reservation) => (
          <ReservacionRow
            key={reservation.class_reservation_id}
            reservation={reservation}
            deleteCallback={getViewData}
          />
        ));
      }
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>

      <div className="card no-scale mb-3">{renderPanel()}</div>

      <div className="card p-3 pb-1 no-scale my-3">
        <PanelTitle title="Compras" onClick={toggleModal} />
        <PurchasesTable
          user={user}
          purchases={customer !== null ? customer.purchases : []}
          confirmRevoke={confirmRevoke}
          confirmCancel={confirmCancel}
        />
      </div>
      <div className="card p-3 pb-1 no-scale my-3">
        <PanelTitle title="Cargos" hideButton />
        <InvoicesTable
          user={user}
          customer_id={customer_id}
          invoices={customer !== null ? customer.invoices : []}
        />
      </div>

      <div className="card no-scale my-3">
        <h3>Reservaciones</h3>
        <table className=" table border">
          <thead className="bg-light border bold small">
            <tr>
              <td>Clase</td>
              <td>Coach</td>
              <td>Fecha</td>
              <td>Reservada en</td>
              <td>Status</td>
              <td>Spot</td>
              <td>Teléfono</td>
              <td>Acciones</td>
            </tr>
          </thead>
          <tbody>
            {renderReservaciones()}
          </tbody>
        </table>
      </div>

      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
