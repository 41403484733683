import { ClassInstructorProvider } from "./context/SingleClassContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { ClassPackagesProvider } from "./context/ClassPackagesContext";
import { AuthProvider } from "./context/AuthContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ReservationsProvider } from "./context/ReservationsContext";

import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { UsersProvider } from "./context/UsersContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { combineComponents } from "./context";
import Main from "./Main";
import "./index.css";
import { AppConfigProvider } from "./context/AppConfigContext";

const AppContextProvider = combineComponents([
  ClassInstructorProvider,
  ClassPackagesProvider,
  ClassCategoryProvider,
  ReservationsProvider,
  AnaliticasProvider,
  ClassTypeProvider,
  LocationsProvider,
  DiscountsProvider,
  ClassTypeProvider,
  VideoTypeProvider,
  InvoicesProvider,
  ProgramsProvider,
  CustomerProvider,
  CheckoutProvider,
  PurchaseProvider,
  MetodosProvider,
  CoachesProvider,
  VideosProvider,
  UsersProvider,
  HomeProvider,
]);

function App() {
  return (
    <AppConfigProvider>
      <ModalProvider>
        <AuthProvider>
          <AppContextProvider>
            <Main />
          </AppContextProvider>
        </AuthProvider>
      </ModalProvider>
    </AppConfigProvider>
  );
}

export default App;
