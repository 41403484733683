import {
  CLEAR_ORDENES,
  HIDE_SPINNER,
  ORDENES_RECIBIDAS,
  SET_ORDEN,
  SHOW_SPINNER,
} from "../types";

const OrdenesReducer = (state, { type, payload }) => {
  switch (type) {
    case ORDENES_RECIBIDAS:
      return { ...state, purchases: payload };
    case CLEAR_ORDENES:
      return { ...state, purchases: null };
    case SET_ORDEN:
      return { ...state, purchase: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};

export default OrdenesReducer;
