import React, { useContext } from "react";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import ClaseForm from "./ClaseForm";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "@reach/router";

const ScheduleClass = ({ singleClass }) => {
  const { modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const { reservations, setPropiedadClase, eliminarClase } = useContext(
    ClassInstructorContext
  );

  const handleEdit = () => {
    modalComponent(
      "Agregar Clase",
      <ClaseForm
        single_class_id={singleClass.single_class_id}
        modifier={setPropiedadClase}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar la clase de{" "}
          {singleClass.class_type.name} del{" "}
          {moment(singleClass.class_date).format("DD MMM YYY")}?
        </p>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => eliminarClase(singleClass.single_class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const isReserved = () => {
    if (reservations && reservations !== null) {
      let class_instructor_ids = reservations.map(
        ({ class_instructor_id }) => class_instructor_id
      );
      return class_instructor_ids.includes(singleClass.class_instructor_id);
    }
    return false;
  };

  const renderSpots = () => {
    if (isReserved()) {
      return (
        <div className="container-fluid text-right px-1">
          <span className="text-pink">
            <i className="fa fa-heart"></i>
          </span>
        </div>
      );
    }
    const available_space =
      singleClass.capacity - singleClass.class_reservations.length;
    if (available_space <= 1) {
      if (available_space <= 0) {
        return <span className="badge badge-pill bg-danger">Sold Out</span>;
      }
      return (
        <div>
          <span className="badge badge-pill bg-verde text-dark">Beginners</span>
          <span className="badge badge-pill bg-warning text-dark">
            {available_space} spot left
          </span>
        </div>
      );
    }
  };

  const renderBeginners = () => {
    if ([3046, 3047].includes(singleClass.class_type_id)) {
      return (
        <span className="badge badge-pill bg-verde text-dark text-capitalize">
          Beginners
        </span>
      );
    }
  };

  const renderInstructors = () => {
    if (singleClass.class_instructors.length === 1) {
      const instructor = singleClass.class_instructors[0].instructor;
      if (instructor !== null) {
        return instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name;
      }
    } else {
      return singleClass.class_instructors
        .map(({ instructor }) =>
          instructor !== null ? (
            <span key={instructor.instructor_id} className="d-block">
              {instructor.nick_name !== null
                ? instructor.nick_name
                : instructor.name}
            </span>
          ) : null
        )
        .filter((instructor) => instructor !== null);
    }
  };

  const renderLocation = () => {
    if (singleClass.location !== null) {
      return singleClass.location.name;
    }
  };

  const renderButtons = () => {
    if (user.role !== "coach") {
      return (
        <div>
          <button
            className="btn btn-dark btn-sm my-1 mx-1"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-danger btn-sm my-1 mx-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="schedule-class text-white p-2 my-2 bg-gray">
      <p className="font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment.utc(singleClass.class_date).format("HH:mm")}
      </p>
      <p className="mb-1 small bold">{renderLocation()}</p>
      <p className="mb-0">{renderInstructors()}</p>
      <Link
        to={`/myadmin/asistentes/${singleClass.single_class_id}`}
        className="btn btn-link text-white px-0 py-0 btn-sm mb-1"
      >
        <i className="fa fa-user"></i> {singleClass.class_reservations.length} /{" "}
        {singleClass.capacity}
      </Link>
      <p className="mb-0">{renderBeginners()}</p>
      {renderButtons()}
    </div>
  );
};

export default ScheduleClass;
