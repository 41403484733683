import React from "react";
import Switch from "react-switch";
import { Link } from "@reach/router";
import { getValue } from "../../utils";
import SpecialEventOptions from "./SpecialEventOptions";
import SingleClassOptions from "./SingleClassOptions";
import SubscriptionOptions from "./SubscriptionOptions";
import ToggleOption from "../common/ToggleOption";
import ClassPackageCategoryOptions from "./ClassPackageCategoryOptions";

const PaqueteForm = ({
  spinner,
  modifier,
  postPaquete,
  class_types,
  class_package,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    postPaquete(class_package);
  };

  const addDefaultPeriod = () => {
    modifier("subscription_period", "month");
    modifier("subscription_interval", "1");
  };

  const renderSingleClassOptions = () => {
    return (
      <SingleClassOptions
        class_package={class_package}
        class_types={class_types}
        modifier={modifier}
      />
    );
  };

  const renderSubscriptionOptions = () => {
    if (getValue(class_package, "is_subscription", "boolean")) {
      return (
        <SubscriptionOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  const renderSpeciaEventOptions = () => {
    if (getValue(class_package, "is_special_event", "boolean")) {
      return (
        <SpecialEventOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  const renderClassPackageCategories = () => {
    if(getValue(class_package, "include_online", "boolean")) {
      return <ClassPackageCategoryOptions />
    }
  }

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <ToggleOption
          label="Disponible"
          value={getValue(class_package, "available", "boolean")}
          modifier={(checked) => modifier("available", checked)}
        />
        <ToggleOption
          label="¿Incluye Acceso Online?"
          value={getValue(class_package, "include_online", "boolean")}
          modifier={(checked) => modifier("include_online", checked)}
        />
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "title")}
          onChange={(e) => modifier("title", e.target.value)}
        />
        <label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "short_description")}
          onChange={(e) => modifier("short_description", e.target.value)}
        />
        <label>Descripción</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "description")}
          onChange={(e) => modifier("description", e.target.value)}
        />
        <label>Precio</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(class_package, "price")}
          onChange={(e) => modifier("price", e.target.value)}
        />
        <label>Precio de Oferta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "sale_price")}
          onChange={(e) => modifier("sale_price", e.target.value)}
        />

        {renderSingleClassOptions()}

        {renderSpeciaEventOptions()}
        {!class_package.is_special_event && (
          <div>
            <h4 className="border-bottom pb-2">Suscripción</h4>
            <div className="row mb-2">
              <div className="col-6">
                <label>¿Es cargo recurrente?</label>
              </div>
              <div className="col-6">
                <Switch
                  checked={getValue(
                    class_package,
                    "is_subscription",
                    "boolean"
                  )}
                  onChange={(checked) => {
                    modifier("is_subscription", checked);
                    if (checked) addDefaultPeriod();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        
        {renderClassPackageCategories()}        
        {renderSubscriptionOptions()}
        <label>Límite de Clientes</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(class_package, "customer_limit")}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => modifier("customer_limit", e.target.value)}
        />
        <label>Límite de Compras por Cliente</label>
        <input
          type="number"
          className="form-control mb-3"
          placeholder="Dejar en blanco para desactivar"
          value={getValue(class_package, "limit_per_customer")}
          onChange={(e) => modifier("limit_per_customer", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
          <div className="col-6 text-right">
            <Link to="/myadmin/videos" className="btn btn-link text-secondary">
              Cancelar
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaqueteForm;
