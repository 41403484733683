import React, { useState, useEffect } from "react";

const ScheduleLocationPicker = ({
  city,
  cityModifier,
  locations,
  modifier,
  selectedLocation,
}) => {
  const [cities, setCities] = useState(null);

  useEffect(() => {
    if (Array.isArray(locations)) {
      extractCities();
    }
  }, [locations]);

  const extractCities = () => {
    const current_cities = locations.map(({ city }) => city);
    setCities(current_cities);
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      return [
        <option key="all" value="">
          Todos los Estudios
        </option>,
        ...locations.map((location) => (
          <option key={location.location_id} value={location.location_id}>
            {location.name}
          </option>
        )),
      ];
    }
  };

  const renderCities = () => {
    if (Array.isArray(cities)) {
      return [
        <option key="all" value="">
          Todas las Ciudades
        </option>,
        ...cities.map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="row ms-0 my-3">
      <div className="container-fluid px-0">
        <label>Buscar por Ciudad</label>
        <select
          value={city}
          className="form-control"
          onChange={(e) => cityModifier(e.target.value)}
        >
          {renderCities()}
        </select>
        <label>Buscar por Estudio</label>
        <select
          value={selectedLocation}
          className="form-control"
          onChange={(e) => modifier(e.target.value)}
        >
          {renderLocations()}
        </select>
      </div>
    </div>
  );
};

export default ScheduleLocationPicker;
