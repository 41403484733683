import React, { useContext, useEffect, useState } from "react";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import { ClassPackagesContext } from "../../context/ClassPackagesContext";
import HeaderRow from "../global/HeaderRow";
import { getArrayDuplicates, getValue } from "../../utils";

const ClassPackageCategoryOptions = () => {
  const [error, setError] = useState("");
  const {
    paquete,
    addClassPackageCategory,
    removeClassPackageCategory,
    setPropertyClassPackageCategory,
  } = useContext(ClassPackagesContext);
  const { class_categories, getClassCategories } =
    useContext(ClassCategoryContext);

  useEffect(() => {
    getClassCategories();
  }, []);

  useEffect(() => {
    if (paquete && paquete !== null) {
      if (Array.isArray(paquete.class_package_categories)) {
        handleClassPackageCategories();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paquete]);

  const handleClassPackageCategories = () => {
    if (paquete.class_package_categories.length === 0) {
      addClassPackageCategory();
    }
    const class_category_ids = paquete.class_package_categories?.map(
      ({ class_category_id }) => class_category_id
    );
    const duplicates = getArrayDuplicates(class_category_ids);
    if (duplicates.length > 0 && error === "") {
      setError(
        "No puedes agregar una categoría dos veces. Por favor, cambia o elimina una de tus categorías repetidas."
      );
    } else if (duplicates.length === 0 && error !== "") {
      setError("");
    }
  };

  const hasEmptyCategory = () => {
    if (Array.isArray(paquete.class_package_categories))
      return paquete.class_package_categories.find(
        ({ class_category_id }) => class_category_id === ""
      );
    return true;
  };

  const renderClassPackageCategories = () => {
    if (Array.isArray(paquete.class_package_categories)) {
      return paquete.class_package_categories.map((class_package_category) => (
        <div
          key={class_package_category.class_category_id}
          className="row align-items-center mx-0 p-2 border"
        >
          <div className="col-3 px-0">
            <select
              className="form-control"
              value={getValue(class_package_category, "class_category_id")}
              onChange={(e) =>
                setPropertyClassPackageCategory(
                  class_package_category.class_category_id,
                  "class_category_id",
                  e.target.value
                )
              }
            >
              {renderClassCategoriesOptions(class_package_category)}
            </select>
          </div>
          <div className="col-3">
            <input
              type="number"
              className="form-control"
              value={getValue(class_package_category, "expiration_days")}
              onChange={(e) =>
                setPropertyClassPackageCategory(
                  class_package_category.class_category_id,
                  "expiration_days",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-3">días</div>
          <div className="col-3">
            <button
              type="button"
              onClick={() =>
                removeClassPackageCategory(
                  class_package_category.class_category_id
                )
              }
              className="btn btn-sm btn-outline-danger"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </div>
      ));
    }
  };

  const renderClassCategoriesOptions = () => {
    if (Array.isArray(class_categories)) {
      return [
        <option key="vacio" value="">
          Elegir una...
        </option>,
        ...class_categories.map((category) => (
          <option
            key={category.class_category_id}
            value={category.class_category_id}
          >
            {category.name}
          </option>
        )),
      ];
    }
  };

  const renderButton = () => {
    if (!hasEmptyCategory()) {
      return (
        <button
          type="button"
          onClick={addClassPackageCategory}
          className="btn btn-sm btn-outline-dark mt-3"
        >
          <i className="fa fa-plus me-2" />
          Agregar Categoría
        </button>
      );
    }
  };

  const renderError = () => {
    if (error && error !== "" && error !== null) {
      return <p className="text-danger my-2">{error}</p>;
    }
  };

  return (
    <div className="mb-4">
      <h3 className="border-bottom pb-2 mb-2">Acceso Online</h3>
      <HeaderRow headers={["Categoría", "Vigencia", "", "Acciones"]} />
      {renderClassPackageCategories()}
      {renderError()}
      {renderButton()}
    </div>
  );
};

export default ClassPackageCategoryOptions;
