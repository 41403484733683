import React, { useContext } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import Chart from "react-apexcharts";
import moment from "moment";
import PanelTitleDate from "../../components/global/PanelTitleDate";

const AnaliticasVideos = () => {
  const { favorites, playbacks, last, hours, getVideos } =
    useContext(AnaliticasContext);

  const renderChart = () => {
    if (hours && hours !== null) {
      let data = [...hours];
      data = hours.map(({ playbacks, hour }) => ({
        playbacks,
        hour: hour - 6 < 0 ? hour - 6 + 24 : hour - 6,
      }));
      data.sort((a, b) => (a.hour < b.hour ? -1 : 1));
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#ffc0e1"],
            xaxis: {
              categories: data.map(({ hour }) => hour),
            },
          }}
          series={[
            {
              name: "Reproducciones por Hora",
              data: data.map(({ playbacks }) => playbacks),
            },
          ]}
        />
      );
    }
  };

  const renderPlaybacks = () => {
    if (Array.isArray(playbacks)) {
      return playbacks.map((video) => (
        <div key={video.video_id} className="row my-2">
          <div className="col-3">{video.name}</div>
          <div className="col-2">{video.instructor_name}</div>
          <div className="col-2">
            {moment(video.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-2">{video.playbacks}</div>
          <div className="col-2">{video.unique_playbacks}</div>
        </div>
      ));
    }
  };

  const renderUltimas = () => {
    if (Array.isArray(last)) {
      return last.map((video) => (
        <div key={video.video_id} className="row my-2">
          <div className="col-3">{video.name}</div>
          <div className="col-2">{video.instructor_name}</div>
          <div className="col-2">
            {moment(video.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-2">{video.playbacks}</div>
          <div className="col-2">{video.unique_playbacks}</div>
        </div>
      ));
    }
  };

  const renderFavorites = () => {
    if (Array.isArray(favorites)) {
      return favorites.map((favorito) => (
        <div key={favorito.video_id} className="row my-2">
          <div className="col-3">{favorito.name}</div>
          <div className="col-3">{favorito.instructor_name}</div>
          <div className="col-3">
            {moment(favorito.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-3">{favorito.favorites}</div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate callback={getVideos} />
      <div className="row">
        <div className="container-fluid">
          <div className="card shadow-sm p-3 no-scale me-3 mb-3">
            <h4 className="border-bottom mb-3 pb-3">Reproducciones</h4>
            <div className="row bold">
              <div className="container-fluid">{renderChart()}</div>
            </div>
          </div>
          <div className="card shadow-sm p-3 no-scale me-3">
            <h4 className="border-bottom mb-3 pb-3">
              Top 10 Más Agregados a favoritos
            </h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-3">Coach</div>
              <div className="col-3">Fecha</div>
              <div className="col-3">Favoritos</div>
            </div>
            {renderFavorites()}
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Top 10 Más Reproducidos</h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-2">Coach</div>
              <div className="col-2">Fecha</div>
              <div className="col-2">Totales</div>
              <div className="col-2">Únicas</div>
            </div>
            {renderPlaybacks()}
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Últimas 10 Clases</h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-2">Coach</div>
              <div className="col-2">Fecha</div>
              <div className="col-2">Totales</div>
              <div className="col-2">Únicas</div>
            </div>
            {renderUltimas()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasVideos;
