import React, { useContext } from "react";
import { LocationsContext } from "../../context/LocationsContext";
import { getValue } from "../../utils";

const LocationForm = ({ modifier, postLocation }) => {
  const { location } = useContext(LocationsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postLocation(location);
  };

  const renderForm = () => {
    if (location && location !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "name")}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Dirección</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "address")}
            onChange={(e) => modifier("address", e.target.value)}
          />
          <label>Ciudad</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "city")}
            onChange={(e) => modifier("city", e.target.value)}
          />
          <label>País</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "country")}
            onChange={(e) => modifier("country", e.target.value)}
          />
          <input type="submit" className="btn btn-dark" value="Guardar" />
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default LocationForm;
