import React, { useContext, useEffect } from "react";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import Switch from "react-switch";

const ClassCategoryForm = ({ class_category_id }) => {
  const {
    class_category,
    createClassCategory,
    setPropiedadCategory,
    postClassCategory,
  } = useContext(ClassCategoryContext);

  useEffect(() => {
    if (isNaN(class_category_id)) {
      createClassCategory();
    }
  }, [class_category_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postClassCategory(class_category);
  };

  const renderForm = () => {
    if (class_category && class_category !== null) {
      const { name, description, available } = class_category;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadCategory("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) =>
              setPropiedadCategory("description", e.target.value)
            }
          />
          <div className="row mb-3">
            <div className="col-6">
              <label>Disponible</label>
            </div>
            <div className="col-6">
              <Switch
                checked={available}
                onChange={(checked) => {
                  setPropiedadCategory("available", checked);
                }}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-dark w-100 mt-3">
            Guardar
          </button>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default ClassCategoryForm;
