import React from "react";

const Footer = () => {
  return (
    <div className="footer container-fluid bg-dark py-3 text-center text-white">
      &copy; 2024 Latina Tribe
    </div>
  );
};

export default Footer;
