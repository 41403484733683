import React, { useContext } from "react";
import Switch from "react-switch";
import PaqueteTypeForm from "./PaqueteTypeForm";
import { ClassPackagesContext } from "../../context/ClassPackagesContext";

const SingleClassOptions = ({ class_package, class_types, modifier }) => {
  const {
    addClassPackageType,
    removeClassPackageType,
    setPropertyClassPackageType,
  } = useContext(ClassPackagesContext);

  const renderClassPackageTypes = () => {
    if (Array.isArray(class_package.class_package_types)) {
      const defaultForm = (
        <PaqueteTypeForm
          key="default"
          isDefault
          modifier={(key, value) => modifier(key, value)}
          class_types={class_types}
          class_package_type={class_package}
        />
      );
      return [
        defaultForm,
        ...class_package.class_package_types.map((current_type) => (
          <PaqueteTypeForm
            key={current_type.class_package_type_id}
            modifier={(key, value) => {
              setPropertyClassPackageType(
                current_type.class_type_id,
                key,
                value
              );
            }}
            class_types={class_types}
            class_package_type={current_type}
            handleDelete={removeClassPackageType}
          />
        )),
      ];
    }
  };

  const renderClassPackageTypesButton = () => {
    if (!class_package.is_special_event) {
      return (
        <button
          type="button"
          onClick={addClassPackageType}
          className="btn btn-sm btn-outline-dark mb-3 d-block"
        >
          <i className="fa fa-plus me-2"></i>Agregar Clases
        </button>
      );
    }
  };

  const { is_special_event } = class_package;

  return (
    <div>
      <h4 className="border-bottom pb-2">Clases Presenciales</h4>
      <div className="row mb-2">
        <div className="col-6">
          <label>Evento Especial</label>
        </div>
        <div className="col-6">
          <Switch
            checked={is_special_event}
            onChange={(checked) => {
              modifier("is_special_event", checked);
              if (checked) modifier("is_subscription", false);
            }}
          />
        </div>
      </div>

      {renderClassPackageTypes()}

      {renderClassPackageTypesButton()}
    </div>
  );
};

export default SingleClassOptions;
