import React, { useContext, useEffect } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import PanelTitle from "../../components/global/PanelTitle";
import ClaseForm from "../../components/clases/ClaseForm";
import { ReservationsContext } from "../../context/ReservationsContext";
import { displayDayTimeMonth } from "../../utils";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import MapaLugares from "../../components/clases/MapaLugares";

const AdminAsistentes = ({ single_class_id }) => {
  const { clase, clearClase, getAsistentes, eliminarClase, setPropiedadClase } =
    useContext(ClassInstructorContext);

  const { postAttend, postPayment, updateGuestName } =
    useContext(ReservationsContext);

  const { eliminarReservacion } = useContext(ReservationsContext);

  const { user } = useContext(AuthContext);

  console.log(user);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAsistentes(single_class_id);
    return () => {
      clearClase();
    };
  }, []);

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <ClaseForm
        single_class_id={single_class_id}
        modifier={setPropiedadClase}
        confirmDeleteClass={confirmDeleteClass}
      />
    );
  };

  const confirmDeleteClass = (clase) => {
    modalComponent(
      "Eliminar Clase",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la clase {clase.description}? Esta
          acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => eliminarClase(clase.single_class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <div className="container-fluid">
        <p>
          ¿Estás segura que deseas cancelar la reservacion de {customer.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            eliminarReservacion(customer.class_reservation_id, () =>
              getAsistentes(single_class_id)
            )
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderClassTypeName = () => {
    if (clase.class_type && clase.class_type !== null) {
      return clase.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (clase.location && clase.location !== null) {
      return clase.location.name;
    }
  };

  const renderHeader = () => {
    let props = { title: "Clase Presencial" };
    if (["admin", "manager"].includes(user.role)) {
      props.onClick = addAsistente;
    }
    return <PanelTitle {...props} />;
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4>Datos de Clase</h4>
            <p>{displayDayTimeMonth(clase.class_date)}</p>
            <p>{renderClassTypeName()}</p>
            <p>{renderLocationName()}</p>
            {["admin", "manager"].includes(user.role) && (
              <div>
                <button
                  className="btn btn-sm btn-outline-dark"
                  onClick={handleEdit}
                >
                  <i className="fa fa-edit me-1"></i> Editar
                </button>
                <button
                  className="btn btn-sm btn-outline-danger mx-3"
                  onClick={() => confirmDeleteClass(clase)}
                >
                  <i className="fa fa-trash me-1"></i> Eliminar
                </button>
              </div>
            )}
            {clase.deletedAt !== null && (
              <div>
                <span className="badge bg-danger">Clase Cancelada</span>
                <p className="mt-2">
                  Los créditos fueron regresados a los usuarios.
                </p>
              </div>
            )}
          </div>
          <div className="col col-md-6">
            {clase.class_type.spot_map !== null && (
              <MapaLugares
                rows={clase.class_type.spot_map
                  .split(",")
                  .map((number) => parseInt(number))}
                taken_spots={clase.asistentes.map(({ spot }) => spot)}
              />
            )}
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="contiainer-fluid px-3">
      {renderHeader()}
      <div className="card p-3 shadow-sm no-scale">{renderClase()}</div>
      <AsistentesTable
        asistentes={clase !== null ? clase.asistentes : []}
        postPayment={postPayment}
        postAttend={postAttend}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        is_special_event={false}
        confirmCancel={confirmCancel}
        user={user}
      />
    </div>
  );
};

export default AdminAsistentes;
