import api from "./api";
import firebase from "firebase";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyBv4thDx5_9Dh98Ik8oZXh6rmNkxmqgV3Q",
  authDomain: "latina-dev.firebaseapp.com",
  projectId: "latina-dev",
  storageBucket: "latina-dev.appspot.com",
  messagingSenderId: "269225159374",
  appId: "1:269225159374:web:bdd18aa54b71ebb4bb7e9f",
};

// Initialize Firebase
if (firebase.apps.length < 1) {
  firebase.initializeApp(firebaseConfig);
}

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        return getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          return user;
        });
      }),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  signOut: () => auth.signOut(),
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  verifyEmail: () => auth.currentUser.sendEmailVerification(),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
  setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
};

export default AuthService;
