import React, { createContext, useContext, useReducer } from "react";
import PurchasesService from "../services/PurchasesService";
import OrdenesReducer from "../reducers/OrdenesReducer";
import {
  CLEAR_ORDENES,
  HIDE_SPINNER,
  ORDENES_RECIBIDAS,
  SET_ORDEN,
  SHOW_SPINNER,
} from "../types";
import { ModalContext } from "./ModalContext";
import { CustomerContext } from "./CustomerContext";
import { hideModal } from "../utils";
import moment from "moment";

const initialState = {
  purchases: null,
  purchase: null,
  spinner: false,
};

export const PurchasesContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { getCustomer } = useContext(CustomerContext);

  const { success, alert } = useContext(ModalContext);

  const getPurchases = (start_date, end_date, filters) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: CLEAR_ORDENES });
    PurchasesService.getPurchases(start_date, end_date, filters)
      .then((res) => {
        const { purchases, invoices } = res.data;
        let total_purchases = [...purchases, ...invoices];
        total_purchases = total_purchases.sort((a, b) =>
          moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
        );
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: ORDENES_RECIBIDAS, payload: total_purchases });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const downloadPurchases = (start_date, end_date, filters) => {
    PurchasesService.downloadPurchases(start_date, end_date, filters)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Purchases_${start_date}_${end_date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_ORDEN, payload: purchase });
    });
  };

  const cancelPurchase = (purchase_id, reason, customer_id) => {
    PurchasesService.cancelPurchase(purchase_id, reason).then(() => {
      success("Compra cancelada con éxito.");
      getCustomer(customer_id);
      hideModal();
    });
  };

  const revokePurchase = (purchase_id, customer_id) => {
    PurchasesService.revokePurchase(purchase_id).then(() => {
      success("Compra revocada con éxito.");
      getCustomer(customer_id);
      hideModal();
    });
  };

  const editExpiration = (purchase_id, expiration_days, customer_id) => {
    PurchasesService.editExpiration(purchase_id, expiration_days).then(() => {
      success("Compra actualizada con éxito");
      getCustomer(customer_id);
    });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getPurchase,
        getPurchases,
        revokePurchase,
        cancelPurchase,
        editExpiration,
        downloadPurchases,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
