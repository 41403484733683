import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import ClassCategoryRow from "../../components/class_categories/ClassCategoryRow";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import ClassCategoryForm from "../../components/class_categories/ClassCategoryForm";

const AdminClassCategories = () => {
  const {
    class_categories,
    setClassCategory,
    getClassCategories,
    deleteClassCategory,
  } = useContext(ClassCategoryContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getClassCategories();
  }, []);

  const createType = () => {
    modalComponent(
      "Agregar Clase",
      <ClassCategoryForm class_category_id="nuevo" />
    );
  };

  const editClassCategory = (class_category) => {
    setClassCategory(class_category);
    modalComponent(
      "Editar Clase",
      <ClassCategoryForm class_category_id={class_category.class_category_id} />
    );
  };

  const confirmDelete = (class_type) => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar la categoría de video "
          {class_type.name}
          "? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteClassCategory(class_type.class_category_id)}
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderCategorias = () => {
    if (class_categories && class_categories !== null) {
      if (class_categories.length === 0) {
        return <p>No hay categorías de video disponibles.</p>;
      }
      return class_categories.map((category) => (
        <ClassCategoryRow
          key={category.class_category_id}
          category={category}
          editClassCategory={editClassCategory}
          confirmDelete={confirmDelete}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3 align-items-center">
        <div className="col-12 col-md-6">
          <h1>Categorías de Videos</h1>
        </div>
        <div className="col-12 col-md-6 text-end">
          <button className="btn btn-accent" onClick={createType}>
            + Agregar
          </button>
        </div>
      </div>
      <div className="row bold mx-0 py-2 bg-light border align-items-center">
        <div className="col-12 col-md-3">Nombre</div>
        <div className="col-12 col-md-2">Descripción</div>
        <div className="col-12 col-md-2">Disponible</div>
        <div className="col-12 col-md-3">Acciones</div>
      </div>
      {renderCategorias()}
    </div>
  );
};

export default AdminClassCategories;
