import React from "react";
import { displayDayTime } from "../../utils";
import { navigate } from "@reach/router";

const SingleClassRow = ({ single_class }) => {
  const renderInstructors = () => {
    if (single_class.class_instructors !== null) {
      if (single_class.class_instructors.length > 0) {
        return single_class.class_instructors
          .map(({ instructor }) => (instructor !== null ? instructor.name : ""))
          .join(",");
      }
    }
    return "N/D";
  };

  const renderLocation = () => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  return (
    <tr
      className="small align-middle hover-light"
      onClick={() =>
        navigate(`/myadmin/asistentes/${single_class.single_class_id}`)
      }
    >
      <td>{displayDayTime(single_class.class_date)}</td>
      <td>{renderInstructors()}</td>
      <td>{renderLocation()}</td>
      <td>
        {single_class.class_reservations.length} / {single_class.capacity}
      </td>
    </tr>
  );
};
export default SingleClassRow;
