import React from "react";
import AsistenteRow from "../customers/AsistenteRow";

const AsistentesTable = ({
  single_class_id,
  asistentes,
  user,
  confirmCancel,
  postAttend,
  postPayment,
  is_special_event,
}) => {
  const renderAsistentes = () => {
    if (Array.isArray(asistentes)) {
      if (asistentes.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return asistentes.map((customer) => (
        <AsistenteRow
          user={user}
          asistente={customer}
          postAttend={postAttend}
          postPayment={postPayment}
          confirmCancel={confirmCancel}
          single_class_id={single_class_id}
          key={customer.class_reservation_id}
          is_special_event={is_special_event}
        />
      ));
    }
  };
  return (
    <div className="table-responsive mt-3">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Nombre</td>
            <td>Lugar</td>
            <td>Instagram</td>
            <td>Correo</td>
            <td>Teléfono</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderAsistentes()}</tbody>
      </table>
    </div>
  );
};

export default AsistentesTable;
