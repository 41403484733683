import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import { LocationsContext } from "../../context/LocationsContext";
import ScheduleMobile from "./ScheduleMobile";
import ScheduleDesktop from "./ScheduleDesktop";
import utils from "./utils";
import "./schedule.css";

const Schedule = () => {
  const [city, setCity] = useState("");
  const [view, setView] = useState("week");
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const {
    days,
    month,
    getSchedule,
    start_date,
    end_date,
    setStartDate,
    setEndDate,
  } = useContext(ClassInstructorContext);

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
    getLocations();
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
  }, [days]);

  useEffect(() => {
    fetchSchedule();
  }, [start_date, end_date]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule(start_date, end_date);
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-1" style={{ overflowX: "hidden" }}>
      <div className="container-fluid ps-0">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-12 col-md-4">
            <ScheduleMonthSelect
              start_date={start_date}
              handleNext={handleNextMonth}
              handlePrev={handlePrevMonth}
            />
            <ScheduleLocationPicker
              locations={locations}
              selectedLocation={location}
              modifier={setLocation}
              cityModifier={setCity}
              city={city}
            />
          </div>
          <div className="col-12 col-md-8 px-0">
            <ScheduleWeekSelect
              start_date={start_date}
              handleNextWeek={handleNextWeek}
              handlePrevWeek={handlePrevWeek}
            />
          </div>
        </div>
      </div>
      <ScheduleDesktop
        city={city}
        location={location}
        weekClasses={currentClasses}
      />
      <ScheduleMobile
        city={city}
        location={location}
        weekClasses={currentClasses}
      />
    </div>
  );
};

export default Schedule;
