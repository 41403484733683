import {
  ADD_CLASS_PACKAGE_CATEGORY,
  ADD_CLASS_PACKAGE_TYPE,
  CREATE_PAQUETE,
  PAQUETES_RECIBIDOS,
  REMOVE_CLASS_PACKAGE_CATEGORY,
  REMOVE_CLASS_PACKAGE_TYPE,
  RESERVATIONS_RECIBIDAS,
  SET_PAQUETE,
  SET_PROPERTY_CLASS_PACKAGE_CATEGORY,
  SET_PROPERTY_CLASS_PACKAGE_TYPE,
  SET_PROPIEDAD_PAQUETE,
} from "../types";

const schema = {
  class_package_id: "nuevo",
  title: "",
  short_description: "",
  description: "",
  price: "",
  sale_price: null,
  available: true,
  class_amount: null,
  customer_limit: null,
  expiration_days: null,
  include_online: false,
  is_special_event: false,
  limit_per_customer: null,
  class_package_types: [],
  class_package_categories: [],
};

const class_package_type_schema = {
  class_type_id: "",
};

const class_package_category_schema = {
  expiration_days: 0,
  class_category_id: "",
};

const hasEmptyCategory = (class_package_categories) => {
  return class_package_categories.find(
    ({ class_category_id }) => class_category_id === ""
  );
};

const PaquetesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAQUETES_RECIBIDOS:
      return { ...state, paquetes: payload };
    case SET_PAQUETE:
      return { ...state, paquete: payload };
    case SET_PROPIEDAD_PAQUETE:
      const { key, value } = payload;
      const paquete = { ...state.paquete };
      paquete[key] = value;
      return { ...state, paquete };
    case CREATE_PAQUETE:
      return { ...state, paquete: schema };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, asistentes: payload };
    case ADD_CLASS_PACKAGE_CATEGORY: {
      const class_package = { ...state.paquete };
      let class_package_categories = class_package.class_package_categories;
      if (!Array.isArray(class_package_categories)) {
        class_package_categories = [];
      }
      if (hasEmptyCategory(class_package_categories)) {
        return { ...state, class_package };
      }
      const current_schema = { ...class_package_category_schema };
      current_schema.class_package_id = class_package.class_package_id;
      class_package_categories.push(current_schema);
      return {
        ...state,
        paquete: { ...class_package, class_package_categories },
      };
    }
    case REMOVE_CLASS_PACKAGE_CATEGORY: {      
      const class_package = { ...state.paquete };
      const class_package_categories = [...class_package.class_package_categories];
      if (Array.isArray(class_package_categories)) {
        const index = class_package_categories.findIndex(
          (current) =>
            String(current.class_category_id) ===
            String(payload)
        );
        if (index !== -1) {
          class_package_categories.splice(index, 1);
        }
      }
      return { ...state, paquete: { ...class_package, class_package_categories } };
    }
    case SET_PROPERTY_CLASS_PACKAGE_CATEGORY: {
      const { key, value, class_category_id } = payload;
      const class_package = { ...state.paquete };
      const class_package_categories = [
        ...class_package.class_package_categories,
      ];
      const category = class_package_categories.find(
        (category) =>
          String(class_category_id) === String(category.class_category_id)
      );
      if (category) {
        category[key] = value;
      }
      return {
        ...state,
        paquete: { ...class_package, class_package_categories },
      };
    }
    case ADD_CLASS_PACKAGE_TYPE: {
      const class_package = { ...state.paquete };
      let class_package_types = class_package.class_package_types;
      if (!Array.isArray(class_package_types)) {
        class_package_types = [];
      }
      if (hasEmptyCategory(class_package_types)) {
        return { ...state, class_package_types };
      }
      const current_schema = { ...class_package_type_schema };
      current_schema.class_package_id = class_package.class_package_id;
      class_package_types.push(current_schema);
      return {
        ...state,
        paquete: { ...class_package, class_package_types },
      };
    }
    case REMOVE_CLASS_PACKAGE_TYPE: {
      const class_package = { ...state.paquete };
      const class_package_types = [...class_package.class_package_types];
      if (Array.isArray(class_package_types)) {
        const index = class_package_types.findIndex(
          (current) =>
            String(current.class_type_id) ===
            String(payload)
        );
        console.log(index)
        if (index !== -1) {
          class_package_types.splice(index, 1);
        }
      }
      return { ...state, paquete: { ...class_package, class_package_types } };
    }
    case SET_PROPERTY_CLASS_PACKAGE_TYPE: {
      const { key, value, class_type_id } = payload;
      const class_package = { ...state.paquete };
      const class_package_types = [...class_package.class_package_types];
      const category = class_package_types.find(
        (category) => String(class_type_id) === String(category.class_type_id)
      );
      if (category) {
        category[key] = value;
      }
      return { ...state, paquete: { ...class_package, class_package_types } };
    }
    default:
      return { ...state };
  }
};

export default PaquetesReducer;
