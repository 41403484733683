import moment from "moment";
import React from "react";
import { Link } from "@reach/router";

const AsistenteRow = ({
  asistente,
  confirmCancel,
  postPayment,
  postAttend,
  single_class_id,
  is_special_event,
}) => {
  const getUrl = (telefono) => {
    telefono = String(telefono).replace("+52", "");
    return `https://wa.me/52${telefono}`;
  };

  const isBirthday = () => {
    let date = moment(asistente.customer.birthdate);
    let day = date.day() + 1;
    let month = date.month() + 1;
    let today = moment();
    let currentDay = today.day();
    let currentMonth = today.month() + 1;
    if (currentDay === day && currentMonth === month) {
      return <i className="fa fa-birthday-cake me-1"></i>;
    }
  };

  const isFirstClass = () => {
    if (asistente.class_reservations.length === 1) {
      return <i className="fa fa-star text-warning"></i>;
    }
    let firstClass = asistente.class_reservations[0];
    if (firstClass) {
      firstClass = firstClass.class_reservation_id;
      if (firstClass === asistente.class_reservation_id) {
        return <i className="fa fa-star text-warning"></i>;
      }
    }
  };

  return (
    <tr className="align-middle small bg-white">
      <td>
        <Link to={`/myadmin/customer/${asistente.customer_id}`}>
          {isBirthday()} {isFirstClass()} {asistente.customer.name}{" "}
          {asistente.customer.last_name}
        </Link>
      </td>
      <td>{asistente.spot}</td>
      <td>
        <i className="fab fa-instagram me-2"></i>
        {"@"}
        {asistente.instagram}
      </td>
      <td>
        <i className="fa fa-envelope me-2"></i>
        {asistente.email}
      </td>
      <td>
        <a href={getUrl(asistente.phone)} className="me-2 text-success">
          <i className="fab fa-whatsapp me-2"></i> {asistente.phone}
        </a>
      </td>
      {!is_special_event && (
        <td>
          <button
            className={`btn m-2 btn-sm btn-${
              !asistente.attend ? "outline-secondary" : "link text-dark"
            }`}
            onClick={() =>
              postAttend(
                asistente.class_reservation_id,
                !asistente.attend,
                single_class_id
              )
            }
          >
            <i className="fa fa-check"></i>
          </button>
          {asistente.payment_method_id > 2 && (
            <button
              className={`btn m-2 btn-sm btn-outline-${
                asistente.is_paid ? "danger" : "success"
              }`}
              onClick={() =>
                postPayment(
                  asistente.class_reservation_id,
                  !asistente.is_paid,
                  single_class_id
                )
              }
            >
              <i className="fa fa-money-bill"></i>
            </button>
          )}
          <button
            className="btn btn-sm btn-outline-danger m-2"
            onClick={() => confirmCancel(asistente)}
          >
            <i className="fa fa-times"></i>
          </button>
        </td>
      )}
    </tr>
  );
};

export default AsistenteRow;
