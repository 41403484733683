import React, { useEffect, useState, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";
import SelectInstructors from "../global/SelectInstructors";

const VideoForm = ({
  video,
  class_categories,
  modifier,
  postVideo,
  class_types,
  video_types,
  instructors,
  addInstructor,
  removeInstructor,
  spinner,
}) => {
  const [addType, setAddType] = useState(false);
  const [type, setType] = useState("");
  const [coach, setCoach] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [picture, setPicture] = useState(null);

  const { alert } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...video };
    if (addType) {
      data.video_type_id = "nuevo";
      data.video_type = {
        name: type,
      };
    }
    if (video.video_type_id === "") {
      return alert("Debes agregar un tipo de video.");
    }
    postVideo(data);
  };

  useEffect(() => {
    if (video !== null) {
      if (video.picture && video.picture !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(video.picture);
      }
    }
  }, [video]);

  useEffect(() => {
    if (video !== null) {
      if (Array.isArray(class_types)) {
        if (
          video.class_type_id === "" ||
          video.class_type_id === null ||
          !video.class_type_id
        ) {
          if (class_types.length > 0) {
            modifier("class_type_id", class_types[0].class_type_id);
          }
        }
      }
      if (Array.isArray(video_types)) {
        if (
          video.video_type_id === "" ||
          video.video_type_id === null ||
          !video.video_type_id
        ) {
          if (video_types.length > 0) {
            modifier("video_type_id", video_types[0].video_type_id);
          }
        }
      }
      if (Array.isArray(class_categories)) {
        if (
          video.class_category_id === "" ||
          video.class_category_id === null ||
          !video.class_category_id
        ) {
          if (class_categories.length > 0) {
            modifier(
              "class_category_id",
              class_categories[0].class_category_id
            );
          }
        }
      }
    }
  }, [class_types, video_types, instructors, video]);

  const renderClassCategories = () => {
    if (Array.isArray(class_categories)) {
      return class_categories.map((class_category) => (
        <option value={class_category.class_category_id}>
          {class_category.name}
        </option>
      ));
    }
  };

  const renderImage = () => {
    const { thumbnail, file } = video;
    if (thumbnail && thumbnail !== null) {
      if (typeof file === "object") {
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="video-form-thumbnail d-block"
          />
        );
      }
      return <img src={thumbnail} className="video-form-thumbnail d-block" />;
    }
    if (picture !== null) {
      return <img src={picture} className="video-form-thumbnail d-block" />;
    }
  };

  const renderTypeForm = () => {
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("video_type_id", e.target.value)}
        value={video.video_type_id}
      >
        {renderVideoTypes()}
      </select>
    );
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(video.video_id)) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <button
        className="btn btn-outline-secondary d-block"
        onClick={() => setChangeImage(true)}
      >
        Cambiar Imagen
      </button>
    );
  };

  const renderForm = () => {
    if (video && video !== null) {
      const {
        name,
        description,
        url,
        class_category_id,
        class_type_id,
        available_at,
      } = video;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Descripcion</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <label>URL de Video</label>
          <input
            type="text"
            className="form-control mb-3"
            value={url}
            onChange={(e) => modifier("url", e.target.value)}
          />
          <div className="row my-3">
            <div className="col-4 col-md-4"> {renderImage()}</div>
            <div className="col-8 col-md-8">
              <label>Thumbnail</label>
              {renderImageForm()}
            </div>
          </div>
          <div className="container-fluid px-0">
            <label>Categoría</label>
            <select
              className="form-control mb-3"
              onChange={(e) => {
                modifier("class_category_id", e.target.value);
              }}
              value={class_category_id}
            >
              {renderClassCategories()}
            </select>
          </div>
          <div className="container-fluid px-0">
            <label>Tipo de Clase</label>
            <select
              className="form-control mb-3"
              onChange={(e) => {
                modifier("class_type_id", e.target.value);
              }}
              value={class_type_id}
            >
              {renderClassTypes()}
            </select>
          </div>
          <div className="container-fluid px-0">
            <label>Tipo de Video</label>
            {renderTypeForm()}
          </div>
          <div className="container-fluid px-0">
            <SelectInstructors
              instructors={instructors}
              addInstructor={addInstructor}
              removeInstructor={removeInstructor}
              selected={video.video_instructors}
            />
          </div>
          <div className="container-fluid px-0">
            <label>Disponible el</label>
            <input
              type="date"
              className="form-control mb-3"
              value={moment(available_at).utc().format("YYYY-MM-DD")}
              onChange={(e) => modifier("available_at", e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="submit"
                className="btn btn-dark btn-block mt-2"
                disabled={spinner}
              >
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/videos"
                className="btn btn-link text-secondary"
              >
                Cancelar
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const handleFiles = (e) => {
    const currentFile = e.target.files[0];
    modifier("picture", currentFile);
  };

  const renderClassTypes = () => {
    if (class_types && class_types !== null) {
      return class_types.map((class_type) => (
        <option key={class_type.class_type_id} value={class_type.class_type_id}>
          {class_type.name}
        </option>
      ));
    }
  };

  const renderVideoTypes = () => {
    if (Array.isArray(video_types)) {
      return video_types.map((video_type) => (
        <option key={video_type.video_type_id} value={video_type.video_type_id}>
          {video_type.name}
        </option>
      ));
    }
  };

  return <div className="container-fluid">{renderForm()}</div>;
};

export default VideoForm;
