import React from "react";
import ScheduleWeek from "./ScheduleWeek";

const WeekScheduleAdminDesktop = ({ weekClasses, handleCallback }) => {
  const renderDays = () => {
    if (Array.isArray(weekClasses)) {
      if (weekClasses.length === 0) {
        return (
          <div className="row">
            <p className="px-0 mb-0 text-center">
              No hay clases programadas esta semana.
            </p>
          </div>
        );
      }
      return (
        <ScheduleWeek week={weekClasses} handleCallback={handleCallback} />
      );
    }
  };
  return (
    <div className="container-fluid hide-mobile">
      <div className="container-fluid ps-0">{renderDays()}</div>
    </div>
  );
};
export default WeekScheduleAdminDesktop;
