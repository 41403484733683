import React, { useContext, useEffect } from "react";
import { getValue, hideModal } from "../../utils";
import ImageInput from "../../components/common/ImageInput";
import MapaLugares from "../../components/clases/MapaLugares";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import ReactSwitch from "react-switch";

const AdminClassTypeForm = ({ class_type_id }) => {
  const {
    class_type,
    getClassType,
    createClassType,
    setPropiedadClassType,
    postClassType,
  } = useContext(ClassTypeContext);

  useEffect(() => {
    if (isNaN(class_type_id)) {
      createClassType();
    } else {
      getClassType(class_type_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_type_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postClassType(class_type);
  };

  const renderForm = () => {
    if (class_type && class_type !== null) {
      const { spot_map, file } = class_type;
      let lugares = String(spot_map)
        .split(",")
        .map((num) => parseInt(num))
        .filter((num) => !isNaN(num));
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(class_type, "name")}
            onChange={(e) => setPropiedadClassType("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(class_type, "description")}
            onChange={(e) =>
              setPropiedadClassType("description", e.target.value)
            }
          />
          <ImageInput
            value={file}
            prop="picture"
            modifier={setPropiedadClassType}
          />
          <div className="row mb-3">
            <div className="col-6">
              <label>Icono</label>
              <input
                type="text"
                className="form-control"
                value={getValue(class_type, "icon")}
                onChange={(e) => setPropiedadClassType("icon", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Color</label>
              <input
                type="color"
                className="form-control mb-3"
                value={getValue(class_type, "color")}
                onChange={(e) => setPropiedadClassType("color", e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-6">
                <label>Restringir Reservas</label>
              </div>
              <div className="col-6 text-right">
                <ReactSwitch
                  checked={getValue(class_type, "restricted", "boolean")}
                  onChange={(checked) =>
                    setPropiedadClassType("restricted", checked)
                  }
                />
              </div>
            </div>
            <label>Lugares</label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Número de columnas por fila, separado por comas"
              value={getValue(class_type, "spot_map")}
              onChange={(e) =>
                setPropiedadClassType("spot_map", e.target.value)
              }
            />
          </div>
          {lugares.length > 0 && (
            <MapaLugares rows={lugares} icon={getValue(class_type, "icon")} />
          )}
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Guardar
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={hideModal}
                className="btn btn-link text-muted w-100 mt-3"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default AdminClassTypeForm;
